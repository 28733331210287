<template>    
    <v-overlay class="text-center">
        <v-progress-circular class="align-self-center" indeterminate size="64" />
        <p>Loading</p>
    </v-overlay>                
</template>

<script>    
    export default {
        name: 'Loading',
    }
</script>